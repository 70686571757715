<template>
	<div>
		<div class='a-booking a-content a-news-single'>
			<div class='app-container app-container-fluid a-container'>
				<div class="a-news-single-breadcrumbs tablet-hide">
					<ul class='a-breadcrumbs-list'>
						<li class='a-breadcrumbs-item active'>
							<router-link :to='$i18nRoute({name: "news"})' class='a-breadcrumbs-link link'>{{$t('routes.news')}}</router-link>
							<v-icon icon='arrow-down' size='9' class='a-breadcrumbs-icon' v-if='!loading' />
						</li>
						<li class='a-breadcrumbs-item' v-if='!loading'>
							<span class='a-breadcrumbs-link'>{{item.title}}</span>
						</li>
					</ul>
				</div>
				<div class='a-booking-container'>
					<div class='a-booking-content'>
						<div class='a-booking-box' v-if='loading'>
							<div class="a-news-single-breadcrumbs tablet-show">
								<span class='skeleton' :style='`width: 100%; height: 17px;`' />
							</div>

							<div class='a-news-single-image'>
								<span class='skeleton' :style='`width: 100%; height: 100%;`' />
							</div>

							<div class='a-news-single-content'>
								<p class='a-news-single-date'><span class='skeleton' :style='`width: 94px; height: 16px;`' /></p>
								<p class='a-content-title a-news-single-title'><span class='skeleton' :style='`width: 70%; height: 33px;`' /></p>
							</div>
						</div>
						<div class='a-booking-box' v-else>
							<div class="a-news-single-breadcrumbs tablet-show">
								<ul class='a-breadcrumbs-list'>
									<li class='a-breadcrumbs-item active'>
										<router-link :to='$i18nRoute({name: "news"})' class='a-breadcrumbs-link link'>{{$t('routes.news')}}</router-link>
										<v-icon icon='arrow-down' size='9' class='a-breadcrumbs-icon' />
									</li>
									<li class='a-breadcrumbs-item'>
										<span class='a-breadcrumbs-link'>{{item.title}}</span>
									</li>
								</ul>
							</div>

							<div class='a-news-single-image'>
								<img :src="item.image">
							</div>

							<div class='a-news-single-content'>
								<p class='a-news-single-date'>{{$moment(item.created_at).format("DD MMMM YYYY")}}</p>
								<p class='a-content-title a-news-single-title'>{{item.title}}</p>

								<div class='a-content-block' v-html='item.body'></div>
							</div>
						</div>
					</div>
					<div class="a-booking-banner"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { getPost } from '@/services/request';

	export default {
		data: () => ({
			item: {},
			loading: false
		}),
		created() {
			this.getPost();
		},
		methods: {
			getPost() {
				this.loading = true;
				getPost(this.$route.params.postId).then(res => this.item = res.data).finally(() => this.loading = false)
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-news-single {
		@include minw( $grid-breakpoints-sm ) {
			.a-booking-box {
				padding: 0;
			}
		}
		@include maxw( $grid-breakpoints-sm - 1 ) {
			.a-heading-1 {
				margin-bottom: 25px;
			}
		}
		&-breadcrumbs {
			margin-bottom: 25px;
		}
		&-image {
			width: 100%;
			height: 48vw;
			border-radius: 10px;
			overflow: hidden;
			@include minw( $grid-breakpoints-sm ) {
				border-radius: 10px 10px 0 0;
				height: 600px * .7;
			}
			@include minw( $grid-breakpoints-md ) {
				height: 600px * .8;
			}
			@include minw( $grid-breakpoints-lg ) {
				height: 600px;
			}
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		&-content {
			padding-top: 20px;
			@include minw( $grid-breakpoints-sm ) {
				padding: 55px 50px 60px;
			}
		}
		&-date {
			font-size: 14px;
			font-weight: 300;
			margin-bottom: 10px;
		}
		&-title {
			margin-bottom: 20px;
			@include minw( $grid-breakpoints-sm ) {
				margin-bottom: 35px;
			}
		}
	}
</style>
